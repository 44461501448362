<template>
  <XyzTransition
    :xyz="animateClasses.join(' ')"
    :mode="transitionMode"
    :appear="initialAnimation"
  >
    <slot></slot>
  </XyzTransition>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'FadeSlideAnimation',
  props: {
    duration: {
      type: [Number, String],
      validator: (value) => {
        const allowed = [0, 0.5, 1, 1.5, 2, 2.5, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30];
        if (allowed.includes(parseFloat(value))) return true;

        throw new Error(`Unsupported animation duration '${value}', allowed: ${allowed}`);
      }
    },
    initialAnimation: {
      type: Boolean,
      default: true
    },
    leaveFirst: {
      type: [Boolean, undefined],
      default: undefined
    },
    directionForward: {
      type: [Boolean, undefined],
      default: undefined
    },
    additionalAnimateClasses: {
      type: String,
    }
  },

  setup (props) {

    const animationDuration = computed(() => {
      let duration = 'duration';

      if (props.duration === undefined) return duration;

      duration += `-${props.duration}`;
      return duration;
    });

    const animationDirection = computed(() => {
      const direction = ['ease-in', ''];
      if (props.directionForward === undefined) return direction;

      direction[0] = `in-${props.directionForward ? 'right' : 'left'}`;
      direction[1] = `out-${props.directionForward ? 'left' : 'right'}`;

      return direction;
    });

    const animateClasses = computed(() => {
      return [
        'fade',
        animationDuration.value,
        ...animationDirection.value,
        props.additionalAnimateClasses
      ];
    });

    const transitionMode = computed(() => {

      if (props.leaveFirst === undefined) return undefined;

      return props.leaveFirst ? 'out-in' : 'in-out';
    });

    return {
      animateClasses,
      transitionMode
    };
  }
};
</script>

<style scoped>
</style>
